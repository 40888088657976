// PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element: Element, isLoggedIn, ...rest }) => {

    return isLoggedIn? <Element /> : <Navigate to="/login" state={{ from: rest.location.pathname }} />

//   return (
//         {...rest}
//         element={
//             isLoggedIn ? (
//                 <Element />
//             ) : (
//                 <Navigate to="/login" state={{ from: rest.location.pathname }} />
//             )
//         }
//   );
};

export default PrivateRoute;

import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import './LoginForm.css';
import axios from 'axios'; 

function LoginForm(props) {
  const navigate = useNavigate();
  const { t, isOpen, onClose, updateIsLoggedIn } = props;
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [loginMessage, setLoginMessage] = useState('');
  const formRef = useRef(null);
  const clickOutsideTimer = useRef(null);

  const handlePhoneNumberChange = (event) => {
    setPhoneNumber(event.target.value);
    setPhoneNumberError('');
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    setPasswordError('');
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Validate form fields
    let isValid = true;

     // Check if phoneNumber and password are non-empty
     if (!phoneNumber) {
      setPhoneNumberError('Phone number is required.');
      isValid = false;
    }

    if (!password) {
      setPasswordError('Password is required.');
      isValid = false;
    }

    if (!isValid) {
      // Form is not valid, do not proceed with the submission
      return;
    }

    // Create a data object with the form fields
    const data = {
      countryCode: '86',
      phoneNumber: phoneNumber,
      password: password,
    };

    try {
      // Make the POST request to your URL
      const response = await axios.post('/api/login/app/phone/password', data);

      // Close the login form after successful login
      if (response.data.code == 200) {
        updateIsLoggedIn(true);
        onClose();
        navigate("/dashboard")
      } else {
        setLoginMessage(response.data.msg);
      }
    } catch (error) {
      // Handle errors (you can show an error message or perform other actions)
      console.error('Error:', error);
    }
  };

  // This useEffect hook will handle the click outside functionality.
  useEffect(() => {
    // When the login form is open (isOpen === true),
    // we'll enable the click outside functionality with a slight delay (100ms).
    // Otherwise, without the delay, LoginForm thinks we are clicking outside when we click 'login' in Header
    // so that it will immediately close itself
    if (isOpen) {
      clickOutsideTimer.current = setTimeout(() => {
        document.addEventListener('click', handleClickOutside);
      }, 10);
    }

    // The cleanup function will be called when the component unmounts
    // or whenever isOpen changes from true to false (i.e., login form is closed).
    return () => {
      // Clear the clickOutsideTimer to avoid triggering the click outside functionality
      // if the login form was closed before the timer expires.
      clearTimeout(clickOutsideTimer.current);
      // Remove the click outside event listener when the login form is closed.
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isOpen]);

  const handleClickOutside = (event) => {
    if (formRef.current && !formRef.current.contains(event.target)) {
      onClose();
    }
  };

  return (
    <div className={`LoginForm ${isOpen ? 'open' : ''}`} ref={formRef}>
      <button className="close-button" onClick={onClose}>
        X
      </button>
      <div className="form">
        <h2>{t('login.form.title')}</h2>
        <form onSubmit={handleFormSubmit}>
          <>
          <label>
              {t('login.form.phoneNumber')}
              <input
                type="text"
                value={phoneNumber}
                onChange={handlePhoneNumberChange}
                className={phoneNumberError ? 'error' : ''}
              />
              {phoneNumberError && <div className="error-message">{phoneNumberError}</div>}
            </label>
          </>
          <>
           <label>
              {t('login.form.password')}
              <input
                type="text"
                value={password}
                onChange={handlePasswordChange}
                className={passwordError ? 'error' : ''}
              />
              {passwordError && <div className="error-message">{passwordError}</div>}
            </label>
            <div className="actions">
              <button type="submit">{t('login.form.submit')}</button>
              <button onClick={onClose}>{t('login.form.close')}</button>
            </div>
            {loginMessage && loginMessage != "ok" && <div className="error-message">{loginMessage}</div>}
          </>
        </form>
      </div>
    </div>
  );
}

export default withTranslation()(LoginForm);
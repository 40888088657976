import './App.css';
import { React, useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import HomePage from './pages/HomePage';
import Dashboard from './pages/Dashboard';
import { withTranslation } from 'react-i18next';
import i18n from './utils/i18n';

function App(props) {
  useEffect(() => {
    i18n.changeLanguage('en'); // change the language for the app
  }, []); // only run this effect once when the component mounts
  const { t } = props;

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const updateIsLoggedIn = (value) => {
    setIsLoggedIn(value);
  }

  return (
    <Routes>
      <Route exact path="/" component={HomePage} element={<HomePage isLoggedIn={isLoggedIn} updateIsLoggedIn={updateIsLoggedIn}/>} />
      <Route
        path="/dashboard"
        element={
          <PrivateRoute element={Dashboard} isLoggedIn={isLoggedIn} />
        }
      />
    </Routes>
  );
};

export default withTranslation()(App);

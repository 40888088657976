import { React, useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../components/Header';

const Dashboard = () => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Function to fetch data from the server
    const fetchData = async () => {
      try {
        // Make the HTTP request using axios or any other method
        const response = await axios.get('/api/psychologist/admin/update/listAll');

        // Update the state with the received data
        setData(response.data.data);
        setLoading(false);
      } catch (error) {
        // Handle any errors that occurred during the HTTP request
        setError(error);
        setLoading(false);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // The empty array [] as the second argument ensures the effect runs only once on mount

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (!data) {
    return <div>No data available.</div>;
  } else {
    console.log(data);
  }

  const approveItem = (itemId) => {
    console.log("approving item: " + itemId);
    const params = {
      updateId: itemId
    }
    axios.put('/api/psychologist/admin/update/approve', null, { params });
  }

  const declineItem = (itemId) => {
    console.log("declining item: " + itemId);
    const params = {
      updateId: itemId
    }
    axios.put('/api/psychologist/admin/update/decline', null, { params });
  }

  return (
    <div>
        <h1>Admin Dashboard</h1>
        <div className="data-container">
            {data.map((item) => (
                <div key={item.id} className="data-item">
                  <h3>{item.psychologistId}</h3>
                  <h3>{item.target}</h3>
                  <div>{item.content}</div>
                  <button onClick={() => approveItem(item.id)}>approve</button>
                  <button onClick={() => declineItem(item.id)}>decline</button>
                </div>
            ))}
        </div>
    </div>
  );

};

export default Dashboard;
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// default English translation
const resources = {
  en: {
    translation: {
      header: {
        title: 'ALONG THE RIVER',
        menu: 'Menu',
        login: 'Login',
        logout: 'Logout'
      }
    }
  },
  cn: {
    translation: {
      header: {
        title: '安流河',
        menu: '目录',
        login: '登录',
        logout: '退出登录'
      }
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // default language
    fallbackLng: 'en', // fallback to English if translation for current language doesn't exist
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
import React, { useRef, useEffect } from 'react';
import './HomePage.css';
import Video from '../assets/streetNearJinganTemple1.mp4';
import Header from '../components/Header';
import { withTranslation } from 'react-i18next';

function HomePage(props) {
  const { t, isLoggedIn, updateIsLoggedIn } = props;
  const videoRef = useRef(null);

  useEffect(() => {
    function handleResize() {
      const video = videoRef.current;
      if (!video) return;

      const { clientWidth, clientHeight } = document.documentElement;
      const { videoWidth, videoHeight } = video;

      if (clientWidth / clientHeight > videoWidth / videoHeight) {
        video.style.width = '100%';
        video.style.height = 'auto';
      } else {
        video.style.width = 'auto';
        video.style.height = '100%';
      }
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="home-page">
      <Header isLoggedIn={isLoggedIn} updateIsLoggedIn={updateIsLoggedIn}/>
      <video ref={videoRef} src={Video} autoPlay muted loop />
      <div className="overlay">
        <h1>安流河心理</h1>
        <h1>COMING SOON</h1>
      </div>
    </div>
  );
}

export default withTranslation()(HomePage);
import { withTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import LoginForm from './LoginForm';

function Header(props) {
  const { t, isLoggedIn, updateIsLoggedIn } = props;

  const [showHeader, setShowHeader] = useState(false);
  const [showLoginForm, setShowLoginForm] = useState(false);

  const navigate = useNavigate();

  const handleMouseEnter = () => {
    setShowHeader(true);
  };

  const handleMouseLeave = () => {
    setShowHeader(false);
  };

  const handleLoginClick = () => {
    setShowLoginForm(!showLoginForm); // Toggle the state
  };

  const handleCloseLoginForm = () => {
    setShowLoginForm(false);
  };

  const handleLogoutClick = () => {
    updateIsLoggedIn(false);
    setShowLoginForm(false); 
    navigate("/");
  }

  return (
    <header
      className="header" style={{ 'backgroundColor': showHeader ? 'white' : 'transparent' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="menu">{t('header.menu')}</div>
      <div className="title">{t('header.title')}</div>
      <nav>
        {!isLoggedIn ? (
          <div className="login" onClick={handleLoginClick}>{t('header.login')}</div>
        ) : (
          <div className="logout" onClick={handleLogoutClick}>{t('header.logout')}</div>
        )}
      </nav>
      {showLoginForm && 
        <LoginForm 
          isOpen={showLoginForm}  
          onClose={handleCloseLoginForm} 
          updateIsLoggedIn={updateIsLoggedIn}
        />
      }
    </header>
  );
}

export default withTranslation()(Header);